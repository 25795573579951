import React, { useEffect } from 'react'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const AffiliateSlider = () => {
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,  // Adjust this based on how many images you want visible at once
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        // responsive: [
        //     {
        //         breakpoint: 1024,
        //         settings: { slidesToShow: 2 }
        //     },
        //     {
        //         breakpoint: 768,
        //         settings: { slidesToShow: 1 }
        //     }
        // ]
    };

    //Slide -in effect for title
    useEffect(() => {
        function handleScroll() {
            const element = document.querySelector('.affiliate-scroll');
            if (element) {
                const elementPosition = element.getBoundingClientRect().top;
                const windowHeight = window.innerHeight;

                if (elementPosition < windowHeight / 2) {
                    element.classList.add('slide-in');
                }
            }
        }

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <>
            <h1 className='affiliate-title affiliate-scroll'>Affiliations/Partnerships</h1>
            <div className='affiliate-container'>
                <div className="affiliate-links">
                    <Slider {...settings}>
                        <div className="affiliate-item">
                            <a href="https://zennigaming.sjv.io/QueenRell" target="_blank" rel="noreferrer"><img src={require('../images/nola-zenni-image.webp')} alt="Zenni Gaming Logo" width="648" height="520" id='zenni-affiliate' /></a>
                        </div>
                        <div className="affiliate-item">
                            <a href="https://glytchenergy.com/?rfsn=7726324.b55fa0" target="_blank" rel="noreferrer"><img src={require('../images/glytch-partner.webp')} alt="Glytch Energy Partner" width="648" height="648" id='glytch-partner' /></a>
                        </div>
                        <div className="affiliate-item">
                            <img src={require('../images/ubisoft-creators.webp')} alt="Ubisoft Creators Program Logo" width="648" height="447" id='ubisoft-partner' />
                        </div>
                        <div className="affiliate-item">
                            <a href="https://spacepandadelta.com/discount/NOLA?ref=NOLA" target="_blank" rel="noreferrer"><img src={require('../images/nola-spacepanda-logo.webp')} alt="Space panda Logo" width="648" height="321" id='panda-partner' /></a>
                        </div>
                        <div className="affiliate-item">
                            <a href="https://bigcreatxr.com/join" target="_blank" rel="noreferrer"><img src={require('../images/bigcreatxr-title.webp')} alt='BigCreatxr Team' width='648' height='260' /></a>
                        </div>

                        <div className="affiliate-item">
                            <a href="https://m.twitch.tv/directory/collection/twitch-unityguild" target="_blank" rel="noreferrer"><img src={require('../images/twitch_black-guild.webp')} alt='Twitch Black Guild' width='648' height='360' /></a>
                        </div>
                        <div className="affiliate-item">
                            <a href="https://www.browngirlgamercode.com/" target="_blank" rel="noreferrer"><img src={require('../images/black-girl-gamer-code.webp')} alt='Black Girl Gamer Code' width='648' height='520' /></a>
                        </div>
                        <div className="affiliate-item">
                            <a href="https://thegamehers.com/" target="_blank" rel="noreferrer"><img src={require('../images/nola-gamehers.webp')} alt='Gamehers' width='648' height='360' /></a>
                        </div>
                        <div className="affiliate-item">
                            <a href="https://1000dreamsfund.org/" target="_blank" rel="noreferrer"><img src={require('../images/nola-dream-fund.webp')} alt='1000 Dream Fund' width='648' height='460' /></a>
                        </div>
                        <div className="affiliate-item">
                            <a href="https://aloebud.app/" target="_blank" rel="noreferrer"><img src={require('../images/nola-AloeBud.webp')} alt='Aloe Bud Ambassador' width='648' height='460' /></a>
                        </div>
                        <div className="affiliate-item">
                            <a href="https://hackwork.net/" target="_blank" rel="noreferrer"><img src={require('../images/nola-hackwork.webp')} alt='Hackwork' width='648' height='360' /></a>
                        </div>
                        <div className="affiliate-item">
                            <a href="https://realmofraines.com/" target="_blank" rel="noreferrer"><img src={require('../images/nola-realm-image.webp')} alt='Realm of Raines' width='648' height='460' /></a>
                        </div>
                    </Slider>
                </div>
            </div>

        </>

    );
};

export default AffiliateSlider;
