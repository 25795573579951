import React, { useEffect } from 'react'

function About() {

    //Slide-in effect for title
    useEffect(() => {
        function handleScroll() {
            const element = document.querySelector('.text-scroll');
            if (element) {
                const elementPosition = element.getBoundingClientRect().top;
                const windowHeight = window.innerHeight;

                if (elementPosition < windowHeight / 2) {
                    element.classList.add('slide-in');
                }
            }
        }

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    return (
        <>
            <div className="about-info-container">
                <div className="about-image">
                    <img src={require('../images/nola-about-pic.webp')} alt='nolaqueenrell' width='304' height='540' />
                </div>
                <div className="about-content">
                    <h1 className='text-scroll'>About Me</h1>
                    <p>Welcome to my world! I'm Rell, an unstoppable force hailing from the vibrant heart of the New Orleans metropolitan area, nestled in the soulful embrace of Louisiana. At my core, I'm a dynamic entrepreneur with an insatiable passion for gaming and creating content that'll keep you on the edge of your seat.</p>
                    <p>My journey as a variety streamer began in 2021, and it's been an electrifying rollercoaster ever since. Not long after I embarked on this exhilarating adventure, I achieved the prestigious title of Twitch Affiliate, a testament to the incredible community we've built together.</p>
                    <p>When you tune in to my channel, you're in for a wild ride through the virtual realms of gaming. My playground spans a diverse spectrum of games, including the chaotic streets of GTA V, heart-pounding matches in Dead by Daylight, hilarious escapades in Rec Room, the whimsical fun of Fall Guys, and the gripping narratives of indie and story-based games. Each session with me promises new adventures, laughter, and unforgettable moments.</p>
                    <p>So, whether you're looking for entertainment, camaraderie, or just a dash of New Orleans' unique flavor, you've come to the right place. Join me in this epic quest of pixels and pixels, and let's create some magical memories together.</p>
                </div>
            </div>
            <br />
            <br />
            <br />
            <br />


        </>
    );
}
export default About;